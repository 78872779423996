import React, { useEffect, useState } from "react";
// import SwapView from "../../constants/swapView";
import { ethers } from "ethers";
import { getPrice } from "../uinswap_v3_price/uinswapbot";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import * as CONTRACTS from "../../Contract";
import Web3 from "web3";
import toast from "react-hot-toast";
import { injectModels } from "../../Redux/injectModels";

const NakaSwap = (props) => {
  const [swapAmount, setSwapAmount] = useState("");
  const [error, setError] = useState("");
  const [nKTValuePrice, setNKTValuePrice] = useState(null);
  const [loading, setLoading] = useState(false);

  const { open } = useWeb3Modal();

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {},
    onDisconnect() {
      window.location.reload();
    },
  });

  const handleSwap = async () => {
    props.application.setLoading(true);

    try {
      props.application.setLoading(true);
      if (Number(swapAmount) > 0) {
        const provider1 = window.ethereum;

        const web31 = new Web3(provider1);
        const contract = new web31.eth.Contract(
          CONTRACTS.ERC20.abi,
          "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
        );
        const tx = contract.methods.approve(ADDRESS, Number(swapAmount) * 1e6);

        props.application.setLoading(true);
        const gas1 = await tx.estimateGas({ from: address });
        const gasPrice1 = await web31.eth.getGasPrice(); // Get current gas price from the network
        const tx1Receipt = await tx.send({
          from: address,
          gas: gas1,
          gasPrice: gasPrice1 * 2,
        });

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const NakaContract = new ethers.Contract(
          ADDRESS,
          CONTRACTS.Nakamoto.abi,
          signer
        );
        const web3 = new Web3(provider);
        const value = Number(swapAmount) * 1e6;
        const swapOldNKT = await NakaContract.swapToken(value);
        await swapOldNKT.wait();

        props.application.setLoading(false);
        toast.dismiss();
        toast.success("Swap Naka Token successfully!");
        setSwapAmount("");
      } else {
        props.application.setLoading(false);
        toast.error(`Error: Enter the swap value`);
      }
    } catch (error) {
      props.application.setLoading(false);
      toast.dismiss();

      if (error.code === "ACTION_REJECTED") {
        toast.error(`User rejected the transaction`);
      } else if (
        error.code === "UNPREDICTABLE_GAS_LIMIT" &&
        error.message.includes("transfer amount exceeds balance")
      ) {
        toast.error(`Error: Insufficient balance to Swap`);
      } else {
        toast.error(`Error: Something went wrong`);
      }

      console.error("Error:", error);
      setSwapAmount("");
    }
  };

  const handleChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9.]/g, "");
    if (numericValue === "") {
      setSwapAmount("");
    } else {
      const sanitizedValue = parseInt(numericValue, 10);

      if (sanitizedValue > 0) {
        setSwapAmount(numericValue);
        setError("");
      } else {
        setError("Please enter a value greater than 0.");
      }
    }
  };

  // const INFURA_KEYS = [
  //   process.env.REACT_APP_INFURA_KEY1,
  //   process.env.REACT_APP_INFURA_KEY2,
  //   process.env.REACT_APP_INFURA_KEY3,
  //   process.env.REACT_APP_INFURA_KEY4,
  // ];

  const getInfuraUrl = () => {
    // const currentHour = new Date().getHours(); // Get the current hour (0 to 23)
    // const keyIndex = Math.floor(currentHour / 6); // Divide the day into 4 segments of 6 hours
    // const selectedApiKey = INFURA_KEYS[keyIndex]; // Select the appropriate API key

    return `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY1}`; // Return full Infura URL with API key
  };



  const getTokenPrice = async () => {
    if (loading) {
      return;
    }
    try {
      const infuraUrl = getInfuraUrl();
      
      setLoading(true);
      const price = await getPrice(
        1, //Amount of token 1
        infuraUrl,
        "WQGDRA3YZDMSFEUVUKRSEDZS3FGGTMCG6W", // Block Scan Api Key
        "0x1B8C03343473Bc648c1b82c85C1DD32C9d63430e", // Pool Address
        "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6" // Quoter Address
      );
      setNKTValuePrice(price);
      setLoading(false);
    } catch (ex) {
      setLoading(false);
      console.log(ex, "price not fetch");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!nKTValuePrice) {
        getTokenPrice();
      } else {
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [nKTValuePrice]);

  const handleConnect = async () => {
    try {
      await open();
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
  };

  return (
    <>
      <section  id="naka_swap" className="naka-swap">
        <div className="container">
          <div className="row">
            <div className="col-md-6 justify-content-center d-flex  align-items-center">
              <div className="white-paper-modal">
                <div className="bg-heading-paper">
                  <h2>
                    {" "}
                    <span> Naka Swap </span>
                  </h2>
                </div>

                <div className="content-section">
                  <h6>
                    Effortless NAKA Token to USDT Swapping on Our Platform
                  </h6>
                  <p>
                    Welcome to Naka Swap, your go-to destination for seamless
                    and secure swapping of NAKA tokens for USDT (Tether) right
                    here on our platform. With our intuitive interface and
                    robust security measures, exchanging your NAKA tokens for
                    USDT has never been easier. Whether you're looking to
                    diversify your cryptocurrency portfolio or simply need to
                    liquidate your NAKA holdings, Naka Swap provides a reliable
                    and efficient solution. Our platform ensures swift
                    transactions, allowing you to capitalize on market
                    opportunities without unnecessary delays.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="swap-nakacard">
                <div className="naka-swap-logo">
                  <div className="logo">
                    <img src="assets/img/nakamoto-logo-left.png" alt="naka" />
                    <h6>Naka Swap</h6>
                  </div>
                  {address ? (
                    <button>
                      <img src="/assets/img/join-now-green.png" alt="" />
                      &nbsp;
                      {`${address.slice(0, 3)}...${address.slice(-4)}`}
                    </button>
                  ) : (
                    <button onClick={handleConnect}>Connect</button>
                  )}
                </div>
                <div className="connect-wallet-tab">
                  <div className="swap-tokennew">
                    <div className="swap-qunantity">
                      <img src="assets/img/polygon.svg" alt="naka" />
                      <img src="assets/img/teder.png" alt="naka" />
                      <div className="usdt-polygn-dert">
                        <p>Polygon</p>
                        <h6>USDT</h6>
                      </div>
                    </div>
                    <div className="usdt-valueinput">
                      <input
                        type="text"
                        value={swapAmount}
                        placeholder="1"
                        onChange={handleChange}
                      />
                      {error && <div className="invalid-feedback">{error}</div>}
                    </div>
                  </div>
                  <div className="swap-tokennew">
                    <div className="swap-qunantity">
                      <img src="assets/img/polygon.svg" alt="naka" />
                      <img src="assets/img/nakamoto-logo-left.png" alt="naka" />
                      <div className="usdt-polygn-dert">
                        <p>Polygon</p>
                        <h6>NKT</h6>
                      </div>
                    </div>
                    <div className="usdt-valueinput">
                      <input
                        type="text"
                        value={
                          nKTValuePrice
                            ? swapAmount
                              ? (swapAmount / Number(nKTValuePrice)).toFixed(2)
                              : (1 / Number(nKTValuePrice)).toFixed(2)
                            : "Fetching..."
                        }
                        readOnly
                      ></input>
                    </div>
                  </div>
                  {/* <div className="arrow-updown">
                          <i className="fa-solid fa-arrow-up" />
                          <i className="fa-solid fa-arrow-down" />
                        </div> */}
                </div>
                <div className="swap-added">
                  {nKTValuePrice ? (
                    <button onClick={handleSwap}>Swap</button>
                  ) : (
                    <button disabled onClick={handleSwap}>
                      Swap
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* <SwapView /> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(NakaSwap);
