import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import * as CONTRACTS from "../../Contract";
import { injectModels } from "../../Redux/injectModels";
import Web3 from "web3";

const TimeLine = (props) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [rank, setRank] = useState("");
  const [rankName, setRankName] = useState("");
  const [rankPercentage, setRankPercentage] = useState("");

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      setWalletAddress(address);
    },
    onDisconnect() {
      window.location.reload();
    },
  });

  const getUserDetails = async () => {
    props.application.setLoading(true);
    try {
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const receipt = await contract.methods.getUser(address).call();
        setRank(receipt[8]);
        props.application.setLoading(false);
      } else {
        console.log("No adddress found");
        props.application.setLoading(false);
      }
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [address, isConnecting, isDisconnected]);

  useEffect(() => {
    switch (rank) {
      case "Naka Starter":
        setRankName(rank);
        setRankPercentage("3");
        break;
      case "Naka Green":
        setRankName(rank);
        setRankPercentage("6");
        break;
      case "Naka Navy":
        setRankName(rank);
        setRankPercentage("10");
        break;
      case "Naka Platinum":
        setRankName(rank);
        setRankPercentage("12");
        break;
      case "Naka Ultimate":
        setRankName(rank);
        setRankPercentage("15");
        break;
      case "Naka Diamond":
        setRankName(rank);
        setRankPercentage("17");
        break;
      case "Naka Pearl":
        setRankName(rank);
        setRankPercentage("18");
        break;
      case "Naka Star":
        setRankName(rank);
        setRankPercentage("19");
        break;
      case "Naka Star Pearl":
        setRankName(rank);
        setRankPercentage("20");
        break;
      default:
        setRankName(rank);
        setRankPercentage("0");
    }
  }, [rank]);

  return (
    <>
      {" "}
      <section className="timeline">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 ftyyer">
              <div className="user-timline">
                <h5>
                  {rank === "No Ranks Achieved" ? (
                    <>No rank achieved yet</>
                  ) : (
                    <>Rank :- {rank}</>
                  )}
                </h5>
                <h2>
                  {rank === "No Ranks Achieved" ? (
                    <>Initial Level</>
                  ) : (
                    <>{rank}</>
                  )}{" "}
                </h2>
                <p>Percentage = {rankPercentage}%</p>
              </div>
            </div>
            <div className="col-md-9">
              <div className="user-timline">
                <ul>
                  <li>
                    <button
                      className={
                        rank === "Naka Green" ||
                        rank === "Naka Starter" ||
                        rank === "Naka Navy" ||
                        rank === "Naka Platinum" ||
                        rank === "Naka Ultimate" ||
                        rank === "Naka Diamond" ||
                        rank === "Naka Pearl" ||
                        rank === "Naka Star" ||
                        rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Starter{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        rank === "Naka Green" ||
                        rank === "Naka Navy" ||
                        rank === "Naka Platinum" ||
                        rank === "Naka Ultimate" ||
                        rank === "Naka Diamond" ||
                        rank === "Naka Pearl" ||
                        rank === "Naka Star" ||
                        rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Green{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        rank === "Naka Navy" ||
                        rank === "Naka Platinum" ||
                        rank === "Naka Ultimate" ||
                        rank === "Naka Diamond" ||
                        rank === "Naka Pearl" ||
                        rank === "Naka Star" ||
                        rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Navy{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        rank === "Naka Platinum" ||
                        rank === "Naka Ultimate" ||
                        rank === "Naka Diamond" ||
                        rank === "Naka Pearl" ||
                        rank === "Naka Star" ||
                        rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Platinum{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        rank === "Naka Ultimate" ||
                        rank === "Naka Diamond" ||
                        rank === "Naka Pearl" ||
                        rank === "Naka Star" ||
                        rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Ultimate{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        rank === "Naka Diamond" ||
                        rank === "Naka Pearl" ||
                        rank === "Naka Star" ||
                        rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Diamond{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        rank === "Naka Pearl" ||
                        rank === "Naka Star" ||
                        rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Pearl{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        rank === "Naka Star" || rank === "Naka Star Pearl"
                          ? "active"
                          : ""
                      }
                    >
                      {" "}
                      Naka Star{" "}
                    </button>
                  </li>
                  <li>
                    <button
                      className={rank === "Naka Star Pearl" ? "active" : ""}
                    >
                      {" "}
                      Naka Star Pearl{" "}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["admin", "application"])(TimeLine);
