import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";

function TableNewBody({
  item,
  allpackages,
  columns,
  startDate,
  endDate,
  page,
  rowsPerPage,
  selectedWallet,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const [incomeHistory, setIncomeHistory] = useState([]);

  useEffect(() => {
    if (item && allpackages && columns) {
      getPackageHistory(item, allpackages);
    }
  }, [item, allpackages, columns, startDate, endDate, selectedWallet]);

  const formatDateMMDDYYYY = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Ensure two-digit month
    const day = ("0" + date.getDate()).slice(-2); // Ensure two-digit day
    const year = date.getFullYear(); // Get full year

    return `${month}/${day}/${year}`;
  };

  const getPackageHistory = async (currentPackage, allpackages) => {
    let phasePercentage;
    if (currentPackage.phase === "0") {
      phasePercentage = 1;
    } else if (currentPackage.phase === "1") {
      phasePercentage = 0.275;
    } else if (currentPackage.phase === "2") {
      phasePercentage = 0.55;
    } else {
      phasePercentage = 0.1852;
    }
    const contractStartTimeStamp = "1711843200";
    const packageStartTimestamp = currentPackage.startingTimestamp;
    const currentTimestamp = `${Date.now() / 1000}`;

    // Calculates required start timestamp for the current package
    const requiredStartTimestamp =
      contractStartTimeStamp > packageStartTimestamp
        ? contractStartTimeStamp
        : packageStartTimestamp;

    // Calculates required end timestamp for the current package
    const requiredEndTimestamp =
      currentTimestamp > currentPackage.endingTimestamp
        ? currentPackage.endingTimestamp
        : currentTimestamp;

    // Get the difference between the timestamps
    const timestampDifference =
      requiredEndTimestamp > requiredStartTimestamp
        ? requiredEndTimestamp - requiredStartTimestamp
        : 0;

    const packageDetails = allpackages[currentPackage.packageIndex];

    if (!packageDetails) {
      console.error(
        "Package details not found for package index:",
        currentPackage.packageIndex
      );
      return;
    }

    const phaseIncome = (packageDetails.price * phasePercentage) / 100;

    let nonWorkingHistory = [];
    if (timestampDifference > 0) {
      const period = timestampDifference / (24 * 60 * 60);
      for (let index = 0; index < period - 1; index++) {
        if (
          currentPackage.phase === "1" ||
          currentPackage.phase === "3" ||
          currentPackage.phase === "5" ||
          currentPackage.phase === "6"
        ) {
          const formattedDate = formatDateMMDDYYYY(
            Math.floor(requiredStartTimestamp) + (index + 2) * 24 * 60 * 60
          );
          nonWorkingHistory.push({
            "s.no": nonWorkingHistory.length,
            amount: `${phaseIncome / 1e18} USDT`,
            date: formattedDate,
            time: "12:00 am",
            wallet: "Naka wallet",
          });
        }
        const formattedDate1 = formatDateMMDDYYYY(
          Math.floor(requiredStartTimestamp) + (index + 2) * 24 * 60 * 60
        );

        nonWorkingHistory.push({
          "s.no": nonWorkingHistory.length,
          amount: `${phaseIncome / 1e18} USDT`,
          date: formattedDate1,
          time: "12:00 am",
          wallet: "Spot wallet",
        });
      }
    }

    setIncomeHistory(nonWorkingHistory);
  };

  const isCurrentDate = (date) => {
    const currentDate = new Date();
    const checkDate = new Date(date);
    return (
      checkDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
      checkDate.getUTCMonth() === currentDate.getUTCMonth() &&
      checkDate.getUTCDate() === currentDate.getUTCDate()
    );
  };

  const filteredRows = incomeHistory.filter((row) => {
    const rowDate = new Date(row.date);
    const isDateInRange =
      !startDate || !endDate || (rowDate >= startDate && rowDate <= endDate);
    const isWalletMatch = !selectedWallet || row.wallet === selectedWallet;
    return isDateInRange && isWalletMatch && !isCurrentDate(row.date);
  });

  // const startIndex = page * rowsPerPage;
  // const endIndex = startIndex + rowsPerPage;
  // const slicedRows = filteredRows.slice(startIndex, endIndex);
  const slicedRows = filteredRows
    .reverse()
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <React.Fragment>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          height: "100%",
          bgcolor: "var(--card-bg)",
          border: "solid 1px rgba(18, 182, 179, 0.4);",
          borderRadius: "5px",
        }}
      >
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      color: "White",
                      backgroundColor: "#12b6b3",
                      borderBottom: "solid 0px rgba(18, 182, 179, 0.4)",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {allpackages && (
              <TableBody>
                {allpackages &&
                allpackages.length === 9 &&
                slicedRows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      align="center"
                      style={{ color: "white" }}
                    >
                      History is only available from 1st April 2024
                    </TableCell>
                  </TableRow>
                ) : (
                  slicedRows.map((row, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ color: "white" }}
                          >
                            {column.id === "name" ? index + 1 : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TableCell
          style={{
            color: "white",
            borderBottom: "solid 0px rgba(18, 182, 179, 0.4)",
          }}
        >
          <div className="table-pagination">
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={filteredRows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ color: "white" }}
            />
          </div>
        </TableCell>
      </Paper>
    </React.Fragment>
  );
}

export default TableNewBody;
