import React, { useState, useEffect } from "react";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Web3 from "web3";
import Swal from "sweetalert2";
import { disconnect } from "@wagmi/core";
import { injectModels } from "../Redux/injectModels";
import * as CONTRACTS from "../../src/Contract";
import { CircularProgress } from "@mui/material";
import { useAccount, useContractWrite, usePrepareContractWrite } from "wagmi";
import { useNetwork } from "wagmi";
import Lottie from "lottie-react";
import comingsoonAnimation from "../animation/coming_soon.json";
import comingsoonTextAnimation from "../animation/coming_soon_text.json";
import FlipNumbers from "react-flip-numbers";
import { getPrice } from "../components/uinswap_v3_price/uinswapbot";

const Header2 = (props) => {
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showTopUpModal2, setShowTopUpModal2] = useState(false);
  const [showTopUpModal3, setShowTopUpModal3] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([true, -1, -1, -1]);
  const [walletAddress, setWalletAddress] = useState("");
  const [sortedAddress, setSortedAddress] = useState("");
  const [isJoined, setIsJoined] = useState("");
  const [isActive, setIsActive] = useState("");
  const [allowance, setAllowance] = useState("");
  const [packageList, setPackageList] = useState([]);
  const [joiningTokenAddress, setJoiningTokenAddress] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [balance, setBalance] = useState("");
  const [decimals, setDecimals] = useState("");
  const [web3Instance, setweb3Instance] = useState();
  const [uplineWalletAddress, setUplineWalletAddress] = useState();
  const [uplineWalletAddressError, setUplineWalletAddressError] = useState();
  const [isUplineAddressVerified, setUplineAddressVerified] = useState(false);
  const [isUplineAddressLoading, setIsUplineAddressLoading] = useState(false);
  const [packageJoiningDetails, setPackageJoiningDetails] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [avialableNKTToken, setAvialableNKTToken] = useState("");
  const [nKTValuePrice, setNKTValuePrice] = useState(null);

  const [termsAcceptedError, setTermsAcceptedError] = useState("");
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState(0);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [eventDate, setEventDate] = useState("");

  const { open } = useWeb3Modal();
  const history = useHistory();

  const ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const BLOCKCHAIN_NETWORK = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const BLOCKCHAIN_ID = process.env.REACT_APP_BLOCKCHAIN_ID;
  const CHAIN_ID = process.env.REACT_APP_CHAINID;
  const { chain, chains } = useNetwork();
  const eventDateLaunch = new Date("2024-03-29T05:30:00.000Z").getTime();
  // const eventDateLaunch = new Date("2024-03-28T15:20:00.000Z").getTime();
  const nowLaunch = new Date().getTime();
  const referalId = localStorage.getItem("referID");
  const referalAddress = localStorage.getItem("referAddress");
  const referalName = localStorage.getItem("referName");
  const referalImage = localStorage.getItem("referImage");

  const getIsJoined = async () => {
    try {
      props.application.setLoading(true);
      const provider = window.ethereum
        ? window.ethereum
        : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

      const web3 = new Web3(provider);
      setweb3Instance(web3);
      const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

      const response = await contract.methods.usersProfile(address).call();

      setIsJoined(response.isJoined);
      localStorage.setItem("isjoined", response.isJoine);
      props.application.setLoading(false);
    } catch (err) {
      console.log(err, "err");
      props.application.setLoading(false);
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  const { address, isConnecting, isDisconnected } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      const firstThree = address.slice(0, 3);
      const lastSix = address.slice(-6);
      const lastSeven = address.slice(-7);
      localStorage.setItem("USERID", lastSeven);
      const sortedAdd = firstThree + "...." + lastSix;

      setSortedAddress(sortedAdd);

      setWalletAddress(address);
      toast.success("Wallet connected successfully!");
    },
    onDisconnect() {
      window.location.reload();
    },
  });

  useEffect(() => {
    if (address) {
      getIsJoined();
    }

    const checkNetwork = async () => {
      try {
        if (address) {
          if (window.ethereum) {
            const networkId = await window.ethereum.request({
              method: "net_version",
            });
            if (parseInt(networkId, 10) === parseInt(CHAIN_ID, 10)) {
              getPackageJoiningDetails();
              getUserStatus();
            } else {
              toast.dismiss();
              toast.error(
                `Please connect to the correct network.
               Required Chain ID: ${CHAIN_ID}, but you're connected to: ${networkId}`
              );
            }
          } else {
            getPackageJoiningDetails();
            getUserStatus();
          }
        }
      } catch (err) {
        console.log(err, "err");
      }
    };

    checkNetwork();
  }, [address, isConnecting, isDisconnected]);

  useEffect(() => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    // const eventDate = new Date("2024-03-29T11:00:00.000Z").getTime();
    const eventDate = new Date("2024-03-29T05:30:00.000Z").getTime();
    // const eventDate = new Date("2024-03-28T15:20:00.000Z").getTime();

    setEventDate(eventDate && eventDate);
    const interval = setInterval(() => {
      const now = new Date().getTime();

      if (now < eventDate) {
        const distance = eventDate - now;

        const newDays = Math.floor(distance / day);
        const newHours = Math.floor((distance % day) / hour);
        const newMinutes = Math.floor((distance % hour) / minute);
        const newSeconds = Math.floor((distance % minute) / second);

        if (newDays !== days) setDays(newDays);
        if (newHours !== hours) setHours(newHours);
        if (newMinutes !== minutes) setMinutes(newMinutes);
        if (newSeconds !== seconds) setSeconds(newSeconds);
      } else {
        handleCloseTopUpModal3();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [days, hours, minutes, seconds]);

  const completeSucess = async (data) => {
    if (data.hash) {
      handleCloseTopUpModal2();

      const data = await getPackageJoiningDetails();

      setDecimals(data.joiningTokenDecimals_);

      setTokenName(data.joiningTokenName_);
      setJoiningTokenAddress(data.joiningTokenAddress_);
      setBalance(data.userTokenBalance_);
      setTokenSymbol(data.joiningTokenSymbol_);
      setAllowance(data.nakaMLMTokenAllowance_);
      props.application.setLoading(false);
      toast.success("Approved");
      handleOpenTopUpModal();
    } else {
      toast.error("something went wrong");
    }
  };

  const completeJoinSuccess = async (data) => {
    props.application.setLoading(false);
    localStorage.setItem("isjoined", true);
    localStorage.setItem("isActive", true);
    handleRedirect();
    toast.success("Transaction sent successfully");
  };

  const { write } = useContractWrite({
    onError(e) {
      switch (currentFunctionIndex) {
        case 1:
          setCurrentFunctionIndex(0);
          toast.error(`An error happened:${e}`);
          props.application.setLoading(false);

          handleCloseTopUpModal2();
          break;
        case 2:
          setCurrentFunctionIndex(0);
          toast.error(`An error happened:${e.details}`);
          props.application.setLoading(false);

          break;
        default:
          setCurrentFunctionIndex(0);
      }
    },

    onSettled(data, error) {
      switch (currentFunctionIndex) {
        case 1:
          setCurrentFunctionIndex(0);
          if (error) {
            toast.error(error && error.details, "error");
          } else {
            completeSucess(data);
          }
          break;
        case 2:
          setCurrentFunctionIndex(0);
          if (error) {
            toast.error(error && error.details, "error");
          } else {
            completeJoinSuccess(data);
          }
          break;

        default:
          setCurrentFunctionIndex(0);
          if (error) {
          } else {
          }
      }
    },
  });

  const handleOpenTopUpModal = () => {
    setShowTopUpModal(true);
  };

  const handleOpenTopUpModal2 = () => {
    setShowTopUpModal(false);
    setShowTopUpModal2(true);
  };
  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
    setSelectedPlan([true, -1, -1, -1]);
  };

  const handleOpenTopUpModal3 = () => {
    setShowTopUpModal3(true);
  };
  const handleCloseTopUpModal3 = () => {
    setShowTopUpModal3(false);
  };

  const handleCloseTopUpModal2 = () => {
    setTermsAccepted("");
    setTermsAcceptedError("");
    setShowTopUpModal(false);
    setShowTopUpModal2(false);
    setUplineWalletAddress();
    setUplineWalletAddressError("");
  };
  // const handleRedirect = () => {
  //   const isActiveCheck = localStorage.getItem("isActive");

  //   if (isActiveCheck === "true") {
  //     history.push({
  //       pathname: "/dashboard",
  //       state: {
  //         walletAddress: walletAddress,
  //       },
  //     });
  //   } else {
  //     toast.dismiss();
  //     // toast.error("Your account is no longer active please contact us");
  //     history.push({
  //       pathname: "/dashboard",
  //       state: {
  //         walletAddress: walletAddress,
  //       },
  //     });
  //   }
  // };

  const handleRedirect = async () => {
    
    const { availableNKTToken, nKTValuePrice } =
      await getRequiredBalanceToLogin();
    const totalValue = availableNKTToken * nKTValuePrice;

    if (totalValue < 1) {
      toast.error("Minimum 1$ worth of NKT is required to login.");
      return;
    }

    const isActiveCheck = localStorage.getItem("isActive");
    const currentAddress = walletAddress;

    try {
      // Request the user to sign a message
      const message = `Please sign this message to verify ownership of wallet: ${currentAddress}`;
      const msg = `0x${Buffer.from(message, "utf8").toString("hex")}`;

      // Send the request to sign the message
      const sign = await window.ethereum.request({
        method: "personal_sign",
        params: [msg, currentAddress],
      });

      // Verify the signature to ensure it's signed by the wallet owner
      const recoveredAddress = await web3Instance.eth.accounts.recover(
        message,
        sign
      );

      if (recoveredAddress.toLowerCase() === currentAddress.toLowerCase()) {
        // If the signature is valid, proceed
        if (isActiveCheck === "true") {
          history.push({
            pathname: "/dashboard",
            state: {
              walletAddress: walletAddress,
            },
          });
        } else {
          toast.dismiss();
          // User is not active, redirect with a message
          history.push({
            pathname: "/dashboard",
            state: {
              walletAddress: walletAddress,
            },
          });
        }
      } else {
        toast.dismiss();
        toast.error("Signature verification failed. Access denied.");
      }
    } catch (error) {
      console.error("Signature verification error:", error);
      toast.dismiss();
      toast.error("Failed to verify ownership. Please try again.");
    }
  };

  const handleDisconnect = async () => {
    Swal.fire({
      background: "#001420",
      title: "Confirmation",
      text: "Are you sure you want to Disconnect?",
      showCancelButton: true,
      confirmButtonColor: "#12b6b3",
      cancelButtonColor: "#373737",

      confirmButtonText: `Yes`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          props.application.setLoading(true);
          localStorage.clear();
          await disconnect();
          localStorage.clear();
          handleDashboardRedirect();
          props.application.setLoading(false);
          toast.success("Logout successfull");
        } catch (err) {
          props.application.setLoading(false);
          console.log(err);
        }
      }
    });
  };
  const handleDashboardRedirect = () => {
    history.push("/");
  };

  const handleJoin = async () => {
    try {
      if (!termsAccepted) {
        setTermsAcceptedError("You must agree before submitting.");
        return;
      } else {
        setTermsAcceptedError("");
      }

      if (window.ethereum) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

        const web3 = new Web3(provider);

        try {
          const contract = new web3.eth.Contract(
            CONTRACTS.Nakamoto.abi,
            ADDRESS
          );

          const getPercentageDifference = await contract.methods
            .getPercentageDifference(
              address,
              uplineWalletAddress ? uplineWalletAddress : referalAddress
            )
            .call();

          const tx = contract.methods.join(
            uplineWalletAddress ? uplineWalletAddress : referalAddress,
            selectedPlan[1],
            getPercentageDifference && getPercentageDifference.userAddressList,
            getPercentageDifference && getPercentageDifference.percentageList
          );

          props.application.setLoading(true);
          handleCloseTopUpModal2();

          const gas2 = await tx.estimateGas({ from: address });
          const gasPrice2 = await web3.eth.getGasPrice();
          // Get current gas price from the network
          const tx2Receipt = await tx.send({
            from: address,
            gas: gas2,
            gasPrice: gasPrice2 * 2,
          });

          props.application.setLoading(false);
          localStorage.setItem("isjoined", true);
          localStorage.setItem("isActive", true);
          handleRedirect();
          toast.success("Transaction sent successfully");
        } catch (error) {
          console.error(`error: ${error.code}`);
          if (`${error.code}` === "4001") {
            handleCloseTopUpModal2();
            props.application.setLoading(false);
            toast.error("User rejected the transaction");
          } else {
            handleCloseTopUpModal2();
            props.application.setLoading(false);
            toast.error(` Error: ${error}`);
          }
        }
      } else {
        toast.error("Ethereum wallet not found");
      }
    } catch (ex) {
      toast.error(ex.message || "An error occurred"); // Ensure it's a string
    }
  };

  const handleSelectPlan = async (plan) => {
    setSelectedPlan(plan);
    localStorage.setItem(
      "selectedPlan",
      JSON.stringify({ plan, selected: true })
    );
  };

  const getRequiredBalanceToLogin = async () => {
    props.application.setLoading(true);
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
    const web3 = new Web3(provider);

    try {
      const infuraUrl = `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY1}`;
      const price = await getPrice(
        1, // Amount of token 1
        infuraUrl, // RPC url
        "WQGDRA3YZDMSFEUVUKRSEDZS3FGGTMCG6W", // Block Scan API Key
        "0x1B8C03343473Bc648c1b82c85C1DD32C9d63430e", // Pool Address
        "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6" // Quoter Address
      );

      const contract = new web3.eth.Contract(
        CONTRACTS.ERC20.abi,
        "0x5dd1cbf142F4B896D18aF835C1734363b0d50fB0"
      );
      const tx = await contract.methods.balanceOf(address).call();
      const availableNKTToken = tx ? web3.utils.fromWei(tx, "ether") : 0;
      props.application.setLoading(false);

      return {
        availableNKTToken: Number(availableNKTToken),
        nKTValuePrice: price,
      };
    } catch (ex) {
      props.application.setLoading(true);

      console.error("Error fetching price or balance:", ex);
      return { availableNKTToken: 0, nKTValuePrice: 0 };
    }
  };

  const getPackageJoiningDetails = async () => {
    if (window.ethereum) {
      try {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

        const web3 = new Web3(provider);
        setweb3Instance(web3);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

        const packageJoiningDetails = await contract.methods
          .getPackagesWithJoiningDetails(address)
          .call();

        if (packageJoiningDetails) {
          setPackageJoiningDetails(packageJoiningDetails);
          setPackageList(packageJoiningDetails[0].slice(0, 6));
          setIsJoined(packageJoiningDetails.isUserAlreadyJoined_);
          localStorage.setItem(
            "isjoined",
            packageJoiningDetails.isUserAlreadyJoined_
          );
        }
        return packageJoiningDetails;
      } catch (err) {
        console.log(err, "err");
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Something went wrong");
      }
    } else {
      try {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

        const web3 = new Web3(provider);
        setweb3Instance(web3);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);

        const packageJoiningDetails = await contract.methods
          .getPackagesWithJoiningDetails(address)
          .call();

        if (packageJoiningDetails) {
          setPackageJoiningDetails(packageJoiningDetails);
          setPackageList(packageJoiningDetails[0].slice(0, 6));
          // setIsJoined(packageJoiningDetails.isUserAlreadyJoined_);
          // localStorage.setItem(
          //   "isjoined",
          //   packageJoiningDetails.isUserAlreadyJoined_
          // );
        }
        return packageJoiningDetails;
      } catch (err) {
        console.log(err, "err");
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Please connect with polygon network");
      }
    }
  };

  const handleCheckboxChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const getUserStatus = async () => {
    try {
      props.application.setLoading(true);
      if (address) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const receipt = await contract.methods.getUserStatus(address).call();

        setIsActive(receipt.isUserActive_);
        localStorage.setItem("isActive", receipt.isUserActive_);
        props.application.setLoading(false);
      } else {
        console.log("No adddress found");
        props.application.setLoading(false);
        props.application.setLoading(false);
      }
    } catch (err) {
      if (window.ethereum) {
        console.log(err, "err");
        props.application.setLoading(false);
        toast.error("Something went wrong");
      } else {
        console.log(err, "err");
      }
    }
  };

  const handleConnectWallet = async () => {
    // if (new Date().getTime() > eventDate) {
    if (isJoined) {
      // if (isActive) {
      //   history.push("/dashboard");
      // } else {
      //   // toast.error("Your account is no longer active please contact us");
      //   history.push("/contact");
      // }
      history.push("/dashboard");
    } else {
      try {
        if (address) {
          const packageDetails = await getPackageJoiningDetails();

          setPackageList(packageDetails && packageDetails[0].slice(0, 6));
          setTokenName(packageDetails && packageDetails.joiningTokenName_);
          setJoiningTokenAddress(
            packageDetails && packageDetails.joiningTokenAddress_
          );
          setBalance(packageDetails && packageDetails.userTokenBalance_);
          setTokenSymbol(packageDetails && packageDetails.joiningTokenSymbol_);
          setAllowance(packageDetails && packageDetails.nakaMLMTokenAllowance_);
          handleOpenTopUpModal();
        } else {
          try {
            await open();
          } catch (error) {
            console.error("Error connecting wallet:", error);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    // history.push("/data-migration")
    // } else {
    //   handleOpenTopUpModal3();
    // }
    // history.push("/under-maintenance")
  };

  const handleConnectWalletBYLogin = async () => {
    if (new Date().getTime() > eventDate) {
      if (address) {
        if (isJoined) {
          history.push("/dashboard");
        } else {
          toast.dismiss();
          toast.error("Please join with any Plan");
        }
      } else {
        open();
      }
      // history.push("/under-maintenance")
    } else {
      handleOpenTopUpModal3();
    }
  };

  const handleAllow = async (allowancePriceInEthers) => {
    try {
      if (window.ethereum) {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);

        try {
          const contract = new web3.eth.Contract(
            CONTRACTS.ERC20.abi,
            joiningTokenAddress
          );
          if (!web3.utils.isAddress(walletAddress)) {
            throw new Error("Invalid wallet address");
          }
          handleCloseTopUpModal2();
          const tx = contract.methods.approve(
            ADDRESS,
            web3.utils.toWei(allowancePriceInEthers, "ether")
          );

          props.application.setLoading(true);
          const gas1 = await tx.estimateGas({ from: address });
          const gasPrice1 = await web3.eth.getGasPrice(); // Get current gas price from the network
          const tx1Receipt = await tx.send({
            from: address,
            gas: gas1,
            gasPrice: gasPrice1 * 2,
          });

          const packageDetails = await getPackageJoiningDetails();

          setPackageList(packageDetails && packageDetails[0].slice(0, 6));
          setTokenName(packageDetails && packageDetails.joiningTokenName_);
          setJoiningTokenAddress(
            packageDetails && packageDetails.joiningTokenAddress_
          );
          setBalance(packageDetails && packageDetails.userTokenBalance_);
          setTokenSymbol(packageDetails && packageDetails.joiningTokenSymbol_);
          setAllowance(packageDetails && packageDetails.nakaMLMTokenAllowance_);
          props.application.setLoading(false);
          handleOpenTopUpModal();
        } catch (error) {
          console.error(error, "error");
          if (error.code == "4001") {
            handleCloseTopUpModal2();

            props.application.setLoading(false);
            toast.error(`User reject the transaction`);
          } else {
            handleCloseTopUpModal2();

            props.application.setLoading(false);
            toast.error(error.message);
          }
        }
      } else {
        try {
          props.application.setLoading(true);
          const provider =
            window.ethereum ||
            new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
          const web3 = new Web3(provider);
          handleCloseTopUpModal2();
          setCurrentFunctionIndex(1);
          write({
            args: [ADDRESS, web3.utils.toWei(allowancePriceInEthers, "ether")],
            address: joiningTokenAddress,
            abi: CONTRACTS.ERC20.abi,
            functionName: "approve",
          });
        } catch (ex) {
          toast.error(`An error happened: ${ex}`);
          props.application.setLoading(false);
          handleCloseTopUpModal2();
          console.log(ex);
        }
      }
    } catch (error) {
      toast.error(`An error happened: ${error}`);
      props.application.setLoading(false);
      handleCloseTopUpModal2();
      console.log(error);
    }
  };

  const handleDefaultUplineID = async () => {
    // setUplineWalletAddress("0x5b4f39a96A95834329c17EF1160239e4f2d2681D");

    const val = "0x5b4f39a96A95834329c17EF1160239e4f2d2681D";

    setUplineAddressVerified(false);
    setUplineWalletAddress(val.trim());
    setUplineWalletAddressError("");
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    if (val.trim() === "") {
      setUplineWalletAddressError("Upline Address is required");
    } else if (!web3.utils.isAddress(val.trim())) {
      setUplineWalletAddressError("Invalid Upline Address");
    } else {
      setIsUplineAddressLoading(true);

      try {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const packageJoiningDetails = await contract.methods
          .getPackagesWithJoiningDetails(val)
          .call();
        const receipt = packageJoiningDetails.isUserAlreadyJoined_;
        if (receipt == true) {
          setUplineWalletAddressError("");
          setUplineAddressVerified(true);
          setIsUplineAddressLoading(false);
        } else {
          setIsUplineAddressLoading(false);
          setUplineWalletAddressError("User not found");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleUplineAddress = async (e) => {
    e.preventDefault();

    const val = e.target.value;

    setUplineAddressVerified(false);
    setUplineWalletAddress(val.trim() ? val.trim() : referalAddress);
    setUplineWalletAddressError("");
    const provider = window.ethereum
      ? window.ethereum
      : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);

    const web3 = new Web3(provider);
    if (val.trim() === "") {
      setUplineWalletAddressError("Upline Address is required");
    } else if (!web3.utils.isAddress(val.trim())) {
      setUplineWalletAddressError("Invalid Upline Address");
    } else {
      setIsUplineAddressLoading(true);

      try {
        const provider = window.ethereum
          ? window.ethereum
          : new Web3.providers.HttpProvider(BLOCKCHAIN_NETWORK);
        const web3 = new Web3(provider);
        const contract = new web3.eth.Contract(CONTRACTS.Nakamoto.abi, ADDRESS);
        const packageJoiningDetails = await contract.methods
          .getPackagesWithJoiningDetails(val)
          .call();
        const receipt = packageJoiningDetails.isUserAlreadyJoined_;
        if (receipt == true) {
          setUplineWalletAddressError("");
          setUplineAddressVerified(true);
          setIsUplineAddressLoading(false);
        } else {
          setIsUplineAddressLoading(false);
          setUplineWalletAddressError("User not found");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleLogoRedirect = () => {
    history.push("/");
  };
  return (
    <React.Fragment>
      <section className="navbar">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="custom-box">
                <div className="row">
                  <div className="col-xxl-6 col-xl-4 col-lg-4 col-md-2">
                    <div className="nav-logo" onClick={handleLogoRedirect}>
                      <img
                        src="/assets/img/nakamoto-logo-left.png"
                        className="img-fluid"
                        alt="logo"
                      />
                      <h1>Naka Token</h1>
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-10">
                    <div className="btn-custom">
                      {isJoined && walletAddress.length > 0 ? (
                        <>
                          <div className="login-btn">
                            <button onClick={handleRedirect}>
                              <i className="fa-solid fa-gauge"></i>
                              <span>Dashboard</span>
                            </button>
                            <button onClick={handleDisconnect}>
                              <i className="fa-solid fa-right-from-bracket"></i>
                              <span>Logout</span>
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="login-btn">
                            <button onClick={handleConnectWalletBYLogin}>
                              {" "}
                              <img
                                src="/assets/img/login-icon.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span>Login</span>
                            </button>

                            <button onClick={handleConnectWallet}>
                              {" "}
                              {walletAddress ? (
                                <>
                                  {" "}
                                  <img
                                    src="/assets/img/join-now-green.png"
                                    className="join-now"
                                    alt=""
                                  />
                                  <span>Select Plan </span>{" "}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <img
                                    src="/assets/img/join-now.png"
                                    className="join-now"
                                    alt=""
                                  />
                                  <span>Join</span>
                                </>
                              )}
                            </button>
                          </div>
                        </>
                      )}
                      <div className="purchasebtn-news">
                        <div className="token-purchase">
                          <a
                            href="https://app.uniswap.org/swap?exactField=input&exactAmount=1&inputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&outputCurrency=0x5dd1cbf142F4B896D18aF835C1734363b0d50fB0&chain=polygon"
                            target="_blank"
                          >
                            <button>
                              {" "}
                              <img
                                src="/assets/img/purchse-token.svg"
                                className="img-fluid"
                                alt="token"
                              />{" "}
                              <span>Purchase Naka Token</span>
                            </button>
                          </a>
                        </div>
                        <div className="news">
                          <Link
                            to={{
                              pathname: "https://nakatoken.io/blog/",
                            }}
                            target="_blank"
                          >
                            {" "}
                            <img
                              src="/assets/img/blog.png"
                              className="join-now"
                              alt=""
                            />{" "}
                            <span>Blog</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showTopUpModal}
        onHide={handleCloseTopUpModal}
        backdrop="static"
      >
        <div className="select-plan-rtfy">
          <Modal.Header closeButton>
            <Modal.Title>Select a Top-Up Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="select-plan">
              <div className="conn-change">
                <div className="gfrty">
                  {" "}
                  <span>Connected with : </span> <h6> {sortedAddress} </h6>
                </div>
                <button
                  onClick={handleDisconnect}
                  className="disconnect-wallet"
                >
                  Change Wallet
                </button>
              </div>
              <ul>
                {packageList &&
                  packageList
                    .filter((plan) => plan[0])
                    .map((plan, index) => (
                      <li key={index}>
                        <button
                          onClick={async () => handleSelectPlan(plan)}
                          className={
                            selectedPlan[2] === plan[2] ? "active" : ""
                          }
                        >
                          $
                          {web3Instance.utils.fromWei(
                            plan[2].toString(),
                            "ether"
                          ) +
                            " " +
                            tokenSymbol}
                        </button>
                      </li>
                    ))}
              </ul>
            </div>
            {selectedPlan[1] < 0 ? (
              <div></div>
            ) : Number(balance / Math.pow(10, decimals)) <
              Number(web3Instance.utils.fromWei(selectedPlan[2], "ether")) +
                Number(web3Instance.utils.fromWei(selectedPlan[3], "ether")) ? (
              <div className="token-error" style={{ color: "red" }}>
                Error : Insufficient balance Please add Min.{" "}
                {Number(web3Instance.utils.fromWei(selectedPlan[2], "ether")) +
                  Number(
                    web3Instance.utils.fromWei(selectedPlan[3], "ether")
                  )}{" "}
                {tokenSymbol} to proceed.{" "}
                <span className="avil">
                  Available Balance is {balance / Math.pow(10, decimals)}{" "}
                  {tokenSymbol}
                </span>
              </div>
            ) : Number(web3Instance.utils.fromWei(allowance, "ether")) <
              Number(web3Instance.utils.fromWei(selectedPlan[2], "ether")) +
                Number(web3Instance.utils.fromWei(selectedPlan[3], "ether")) ? (
              <div className="usdt-allowance">
                <p className="token-error" style={{ color: "#ffcc00" }}>
                  * {web3Instance.utils.fromWei(selectedPlan[3], "ether")}{" "}
                  {tokenSymbol} has been added as a convenience fee
                </p>
                <button
                  className="plan-continue-btn"
                  onClick={async () =>
                    handleAllow(
                      (
                        Number(
                          web3Instance.utils.fromWei(selectedPlan[2], "ether")
                        ) +
                        Number(
                          web3Instance.utils.fromWei(selectedPlan[3], "ether")
                        )
                      ).toString()
                    )
                  }
                >
                  Allow{" "}
                  {Number(
                    web3Instance.utils.fromWei(selectedPlan[2], "ether")
                  ) +
                    Number(
                      web3Instance.utils.fromWei(selectedPlan[3], "ether")
                    )}{" "}
                  {tokenSymbol}
                </button>
              </div>
            ) : (
              <div className="plan-continue-btn">
                <button onClick={handleOpenTopUpModal2}>Continue</button>
              </div>
            )}
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showTopUpModal2}
        onHide={handleCloseTopUpModal2}
        backdrop="static"
      >
        <div className="select-plan-rtfy">
          <Modal.Header closeButton>
            <Modal.Title>Referral Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="contact-inner wallet-input">
              <div className="form-group">
                <div className="referal-user-img">
                  {referalImage && (
                    <img src={referalImage} className="img-referal" alt="img" />
                  )}
                  {referalImage && (
                    <label htmlFor="exampleInputPassword1">{referalName}</label>
                  )}
                  {referalId && (
                    <label htmlFor="exampleInputPassword1">
                      Upline ID: {referalId}
                    </label>
                  )}
                </div>

                <input
                  type=""
                  className="form-control is-invalid "
                  id="exampleInputPassword1"
                  aria-describedby="emailHelp"
                  placeholder="Please enter your Referral ID"
                  value={
                    uplineWalletAddress ? uplineWalletAddress : referalAddress
                  }
                  onChange={handleUplineAddress}
                />
                {!referalId && (
                  <Link
                    to="#"
                    className="donotUpline"
                    onClick={handleDefaultUplineID}
                  >
                    Don't have Upline Id
                  </Link>
                )}
                {uplineWalletAddressError && (
                  <div className="invalid-feedback">
                    {uplineWalletAddressError}
                  </div>
                )}
              </div>
              {!isUplineAddressVerified && !referalAddress ? (
                isUplineAddressLoading ? (
                  <CircularProgress style={{ color: "#12b6b3" }} />
                ) : (
                  <div></div>
                )
              ) : (
                <>
                  <div className="col-12 mt-2 agree">
                    <div className="form-check agree-term">
                      <input
                        className="form-check-input"
                        name="terms"
                        type="checkbox"
                        checked={termsAccepted}
                        id="acceptTerms"
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="acceptTerms">
                        I agree and accept the{" "}
                        <Link to="/terms-and-condition" target="_blank">
                          terms and conditions
                        </Link>
                      </label>
                    </div>

                    {termsAcceptedError && (
                      <span className="error">{termsAcceptedError}</span>
                    )}
                  </div>

                  <div className="plan-continue-btn">
                    <button onClick={handleJoin}> Join</button>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showTopUpModal3}
        onHide={handleCloseTopUpModal3}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="select-plan-rtfy">
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>
            <Lottie
              style={{ height: 150, marginTop: 50 }}
              animationData={comingsoonAnimation}
              loop={true}
            />
            <Lottie
              style={{ height: 175, margin: 0, marginTop: -50 }}
              animationData={comingsoonTextAnimation}
              loop={true}
            />

            {nowLaunch < eventDateLaunch && (
              <section className="coming-soon">
                <div className="container">
                  <div id="countdown" className="coming-count">
                    <ul>
                      <li>
                        <FlipNumbers
                          color="#12b6b3"
                          background="#191a1a"
                          height={16}
                          width={16}
                          play
                          perspective={100}
                          numbers={`${days}`}
                          numberStyle={{ color: "#12b6b3", fontSize: "15px" }}
                        />
                        <h6>Days</h6> <small>D</small>
                      </li>
                      <li>
                        <FlipNumbers
                          color="#12b6b3"
                          background="#191a1a"
                          height={16}
                          width={16}
                          play
                          perspective={100}
                          numbers={`${hours}`}
                          numberStyle={{ color: "#12b6b3", fontSize: "15px" }}
                        />
                        <h6>Hours</h6> <small>H</small>
                      </li>
                      <li>
                        <FlipNumbers
                          color="#12b6b3"
                          background="#191a1a"
                          height={16}
                          width={16}
                          play
                          perspective={100}
                          numbers={`${minutes}`}
                          numberStyle={{ color: "#12b6b3", fontSize: "15px" }}
                        />
                        <h6>Minutes</h6> <small>M</small>
                      </li>
                      <li>
                        <FlipNumbers
                          color="#12b6b3"
                          background="#191a1a"
                          height={16}
                          width={16}
                          play
                          perspective={100}
                          numbers={`${seconds}`}
                          numberStyle={{ color: "#12b6b3", fontSize: "15px" }}
                        />
                        <h6>Seconds</h6> <small>S</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(Header2);
