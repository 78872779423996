import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Banner from "./Banner";
import RecentActivity from "./RecentActivity";
import WhitePaper from "./WhitePaper";
import Roadmap from "./Roadmap";
import FaqHome from "./FaqHome";
import Channels from "./Channels";
import TransitionGraph from "./TransitionGraph";
import { useParams } from "react-router-dom";
import { injectModels } from "../../Redux/injectModels";
import NakaSwap from "./nakaSwap";
import NakaCoin from "./NakaCoin";

const Home = (props) => {
  const location = useLocation();
  const param = useParams();
  console.log(param.referId,"param value")
  useEffect(() => {
    const getUplineId = async () => { 
      if (param.referId) {
        const response = await props.admin.getReferalWalletAddress(
          param.referId
        );
        if (response != null) {
          localStorage.setItem("referID", param.referId);
          localStorage.setItem("referName", response.firstName);
          localStorage.setItem("referImage", response.avatar);
          localStorage.setItem("referAddress", response.walletAddress);
        } else {
          localStorage.setItem("referID", "");
          localStorage.setItem("referName", "");
          localStorage.setItem("referImage", "");
          localStorage.setItem("referAddress", "");
        }
      }
    };
    getUplineId();
  }, [param.referId, props.admin]);

  useEffect(() => {
    // Check if the URL has a fragment identifier
    if (location.hash === "#naka_swap") {
      const nakaSwapElement = document.getElementById("naka_swap");
      if (nakaSwapElement) {
        nakaSwapElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);


  return (
    <>
      <Banner />
      <RecentActivity />
      <TransitionGraph /> 
      <NakaCoin />
      <NakaSwap />
      <WhitePaper />
      <Roadmap />
      <FaqHome />
      <Channels />
    </>
  );
};

export default injectModels(["admin", "application"])(Home);
